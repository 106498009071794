import React from 'react';
import PricingRoot from '../views/Pricing/PricingRoot';
import Layout from '../layout/Layout';
import { Seo } from '../components/Seo'
import { graphql } from 'gatsby';

const PricingPage = (props) => (
	<Layout>
		<Seo
			metaDescription={"Making an account is free, eliminate paperwork from your life today! See our pricing."}
			title={"Pricing"}
			slug={'/pricing'}
		/>
		<PricingRoot data={props.data} />
	</Layout>
);

export default PricingPage;

export const query = graphql`
	query PricingPage {
		pricing: allContentfulPricing {
			edges {
				node {
					title
					order
					price
					descriptor
					description
					features
				}
			}
		}
	}
`;
