import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Card } from '../../theme/components';

export const PricingRow = styled(Row)`
  width: 100%;
`;

export const PricingCard = styled(Col)`
  margin-bottom: 24px;
`;

export const PricingCardContents = styled(Card)`
	height: 100%;
	width: 100%;
	padding: 32px;
  min-height: 500px;
  justify-content: center;
`;

export const PricingCardContentsPink = styled(PricingCardContents)`
  border: 0;
  background: rgb(255,42,76);
	background: linear-gradient(180deg, rgba(238,44,80,1) 0%, rgba(228,32,96,1) 100%);
`;

export const PricingTitle = styled.h2`
	margin-bottom: 0;
`;

export const PricingPrice = styled.p`
	font-size: 18px;
	margin-bottom: 0;
`;

export const PricingPriceBold = styled.span`
	font-size: 24px;
`;

export const SizeBadge = styled.div`
	height: 40px;
	background-color: #FDE8EC;
	color: #EE2D4F;
	padding: 8px 18px;
	border-radius: 6px;
	font-size: 16px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 12px;
`

export const PricingDescriptor = styled.h3`
  font-size: 24px;
`;

export const PricingDescriptorWhite = styled(PricingDescriptor)`
  color: white !important;
`;

export const WhiteText = styled.p`
  color: white;
`;