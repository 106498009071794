import React, { Component } from 'react';
import { PricingPageData } from '../../models/common';
import { CenteredSectionSmall, DottedList, DottedListItemWhite, DottedListItem, PageContents } from '../Home/styled';
import { Hero, HeroContents, HeroBadge, HeroTitle, HeroDescription, DownIcon } from '../HowItWorks/styled';
import Header from '../../layout/Header';
import down from '../../images/down.svg';
import {
	PricingRow,
	PricingCard,
	PricingTitle,
	PricingPrice,
	PricingPriceBold,
	PricingCardContents,
	SizeBadge,
	PricingDescriptor,
	PricingCardContentsPink,
	PricingDescriptorWhite,
	WhiteText
} from './styled';
import { Row, Col, Divider } from 'antd';
import { dynamicSort } from '../../helpers/arrays';
import { Button, LinkButton } from '../../theme/components';

interface Props {
	data: PricingPageData;
}

const PricingRoot: React.FC<Props> = (props) => {
	const contents = (pricing: any) => {
		return (
			<React.Fragment>
				<SizeBadge>{pricing.title}</SizeBadge>
				<PricingRow type='flex' align='middle' justify='space-between'>
					<Col>
						<PricingPrice>
							<PricingPriceBold>
								{pricing.price === '0' ? 'Free' : `$${pricing.price}`}
							</PricingPriceBold>{' '}
							{pricing.price === '0' ? '' : 'per QR code'}
						</PricingPrice>
					</Col>
					<Col>
						<LinkButton
							href='https://app.dereader.ca/register'
							target='_blank'
							rel='noreferrer noopener'
							color='primary'
							size="small"
						>
							Get Started
												</LinkButton>
					</Col>
				</PricingRow>
				<Divider />
				<div>
					<PricingDescriptor>{pricing.descriptor}</PricingDescriptor>
					<p>{pricing.description}</p>
				</div>
				<Divider />
				<div>
					<PricingDescriptor>Features</PricingDescriptor>
					<DottedList>
						{pricing.features.map((feature) => {
							return <DottedListItem key={feature}>{feature}</DottedListItem>;
						})}
					</DottedList>
				</div>
			</React.Fragment>
		)
	}
	const contentsDark = (pricing: any) => {
		return (
			<React.Fragment>
				<HeroBadge style={{ height: 40, fontSize: 16 }}>{pricing.title}</HeroBadge>
				<PricingRow type='flex' align='middle' justify='space-between'>
					<Col>
						<PricingPrice style={{ color: "white" }}>
							<PricingPriceBold style={{ color: "white" }}>
								{pricing.price === '0' ? 'Free' : `$${pricing.price}`}
							</PricingPriceBold>{' '}
							{pricing.price === '0' ? '' : 'per QR code'}
						</PricingPrice>
					</Col>
					<Col>
						<LinkButton
							href='https://app.dereader.ca/register'
							target='_blank'
							rel='noreferrer noopener'
							color='secondary'
							size="small"
						>
							Get Started
						</LinkButton>
					</Col>
				</PricingRow>
				<Divider />
				<div>
					<PricingDescriptorWhite>{pricing.descriptor}</PricingDescriptorWhite>
					<WhiteText>{pricing.description}</WhiteText>
				</div>
				<Divider />
				<div>
					<PricingDescriptorWhite>Features</PricingDescriptorWhite>
					<DottedList>
						{pricing.features.map((feature) => {
							return <DottedListItemWhite key={feature}>{feature}</DottedListItemWhite>;
						})}
					</DottedList>
				</div>
			</React.Fragment>
		)
	}
	return (
		<React.Fragment>
			<Hero>
				<Header />
				<HeroContents>
					<HeroBadge>We Provide Value</HeroBadge>
					<HeroTitle>Pricing</HeroTitle>
					<HeroDescription>Making an account is free, eliminate paperwork from your life today! See our pricing below.</HeroDescription>
					<DownIcon src={down} alt="Scroll Down" />
				</HeroContents>
			</Hero>
			<PageContents>
				<CenteredSectionSmall style={{ marginTop: 24 }}>
					<Row type="flex" align="middle" justify="center" gutter={24}>
						{props.data.pricing.edges
							.map(({ node }) => {
								return node;
							})
							.sort(dynamicSort('order'))
							.map((pricing, index) => {
								if (index === 1) {
									return (
										<PricingCard key={pricing.title} md={12} sm={24} xs={24}>
											<PricingCardContentsPink>
												{contentsDark(pricing)}
											</PricingCardContentsPink>
										</PricingCard>
									);
								} else {
									return (
										<PricingCard key={pricing.title} md={12} sm={24} xs={24}>
											<PricingCardContents>
												{contents(pricing)}
											</PricingCardContents>
										</PricingCard>
									);
								}
							})}
					</Row>
				</CenteredSectionSmall>
			</PageContents>
		</React.Fragment>
	);
};

export default PricingRoot;
